// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/mattmadeiro/Work/space-city-cups/space-city-cups/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/Users/mattmadeiro/Work/space-city-cups/space-city-cups/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-engraving-js": () => import("/Users/mattmadeiro/Work/space-city-cups/space-city-cups/src/pages/custom-engraving.js" /* webpackChunkName: "component---src-pages-custom-engraving-js" */),
  "component---src-pages-faq-js": () => import("/Users/mattmadeiro/Work/space-city-cups/space-city-cups/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/Users/mattmadeiro/Work/space-city-cups/space-city-cups/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-work-js": () => import("/Users/mattmadeiro/Work/space-city-cups/space-city-cups/src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */)
}

exports.data = () => import("/Users/mattmadeiro/Work/space-city-cups/space-city-cups/.cache/data.json")

